import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/common/services/service/data.service';
import { HttpService } from 'src/app/common/services/service/http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


// Constants
import { URLConstants } from '../../common/constants/routerLink-constants';

// Service
import { MetaDataService } from '../../common/services/service/meta-data.service';

// Interfaces
import { GetStoreResponse, GetMenuCategories } from '../../shared/Interface';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styles: [
    'a.disabled { pointer-events: none; cursor: default;}'
  ]
})
export class HomePageComponent implements OnInit, AfterViewInit {
  URLConstants = URLConstants;
  public categoryList = [];
  public restaurantName = '';
  public restLogo = '';
  public zone = 0;
  private storeId = '';
  private currentUrl: any = '';
  limit: number;
  public isLoading = true;

  categoryDetails: GetMenuCategories = {
    zone: 0,
    menuId: '',
    itemCount: 0,
    limit: 100,
  };

  constructor(private metaDataService: MetaDataService,
              private httpService: HttpService,
              private route: ActivatedRoute,
              private dataService: DataService,
              private loaderService: NgxUiLoaderService) {
    this.route.queryParamMap.subscribe(params => {
      this.loaderService.start();
      this.storeId = params.get('r');
      this.currentUrl = this.route.url;
      if (this.storeId != null && this.storeId !== '') {
        this.getRestInfoAndCategories();
      } else {
        this.deepLinkingURLForwading();
      }
    });
  }

  ngAfterViewInit(): void {
    this.loaderService.stop();
    this.isLoading = false;
  }

  getRestInfoAndCategories() {
    this.httpService.getStoreDetails({ restId: this.storeId })
      .subscribe((res: GetStoreResponse) => {
        this.restaurantName = res.data.restInfo.restaurantName;
        this.restaurantName = res.data.restInfo.restaurantName;
        this.restLogo = res.data.restInfo.restLogo;
        this.zone = res.data.restInfo.zone;
        this.categoryList = res.data.items;

        localStorage.setItem('logo', this.restLogo);
        this.dataService.setData(this.restLogo, this.restaurantName);
      });
  }

  deepLinkingURLForwading() {
    this.dataService.deepLinkingURLForwading(window);
  }

  ngOnInit() {
    this.metaDataService.setMetaData(this.URLConstants.HOMEPAGE);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { callAPIConstants } from '../../constants/callAPI-constants';


// Interfaces 
import {Store, GetMenuCategories,GetSubCategory } from '../../../shared/Interface';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private baseUrl;

    constructor(private http: HttpClient) { 
        this.baseUrl = environment.apiUrl
    }


    public getStoreDetails(data: Store){
        return this.http.post(this.baseUrl + callAPIConstants.GetStoreDetails, data)
    }

    public getMenuCategories(data: GetMenuCategories){
        return this.http.post(this.baseUrl + callAPIConstants.GetMenuCategories, data)
    }

    public getItemsBySubCategory(data: GetSubCategory){
        return this.http.post(this.baseUrl + callAPIConstants.GetSubCategories, data )
    }

    public getTemp() {
        return this.http.get("https://www.shab.ae/apps.html?604455cf28b19a53d422fd2b_1##_shab_604455cf28b19a53d422fd2b_1")
    }

}
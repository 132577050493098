import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating'
import { TabsModule } from 'ngx-bootstrap/tabs';

// Plugins
import { OwlModule } from 'ngx-owl-carousel';
import { NgSelectModule } from '@ng-select/ng-select';

import { HighchartsChartModule } from 'highcharts-angular';


@NgModule({
  declarations: [
  ],
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    OwlModule,
    NgSelectModule,
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    HighchartsChartModule,
  ],
  exports: [
    // Modules
    FormsModule,
    OwlModule,
    NgSelectModule,
    RatingModule,
    TabsModule,
    HighchartsChartModule,

  ],
  providers: [BsModalRef],
})
export class SharedModule { }

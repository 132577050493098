<!-- header -->
<!-- <app-header></app-header> -->
<!-- header -->

<router-outlet *ngIf="!loading"></router-outlet>
<ngx-ui-loader></ngx-ui-loader>


<!-- footer -->
<!-- <app-footer></app-footer> -->
<!-- footer -->
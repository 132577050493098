import {AfterViewInit, Component, Inject, Injector, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';

// Constants
import { URLConstants } from './common/constants/routerLink-constants';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit{
  URLConstants = URLConstants;
  loading  = true;
  private platformId: any;

  constructor(
      public router: Router,
      private loaderService: NgxUiLoaderService,
      private injector: Injector
              ,@Inject(DOCUMENT) document) {
    this.platformId = injector.get(PLATFORM_ID);
    this.loaderService.start();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('loader').style.display = 'none';
      this.loading = false;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// Auth Guard
import { CanLoginActivate, CanAuthActivate } from './common/services/TS-files/auth.gaurd';
import { HomePageModule } from './public/home-page/home-page.module';

const routes: Routes = [
  { path: '', redirectTo: 'categories', pathMatch: 'full' },
  { path: 'categories', loadChildren: () => import('./public/home-page/home-page.module').then((m) => m.HomePageModule) },
  // tslint:disable-next-line:max-line-length
  { path: 'products/:id/:zone/:name', loadChildren: () => import('./public/product-detail/product-detail.module').then(p => p.ProductDetailModule) },
  { path: '**', redirectTo: 'home-page' }
];

@NgModule({
  imports: [HomePageModule, CommonModule, RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule],
  providers: [CanLoginActivate, CanAuthActivate]
})
export class AppRoutingModule { }

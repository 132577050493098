import {Injectable} from "@angular/core";
import {Subject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService{
    private data = "";
    private restName = "";
    constructor() {}

    public setData(url: string, restName: string): void {
        this.data = url
        this.restName = restName;
    }

    public getData(): any{
        return [this.restName, this.data];
    }

    public deepLinkingURLForwading(window) {
        if (/Android/i.test(window.navigator.userAgent)) {
          //alert('Android');
          window.location.href = "https://play.google.com/store/apps/details?id=com.shabapps.shab";
        } else if (/webOS/i.test(window.navigator.userAgent)) {
          //	alert('webOS');
          window.location.href = "https://www.yahoo.com";
        } else if (/iPhone/i.test(window.navigator.userAgent)) {
          //	alert('iPhone');
          window.location.href = "https://apps.apple.com/ae/app/shab-food-ordering-app/id1469194074";
        } else if (/iPad/i.test(window.navigator.userAgent)) {
          //	alert('iPad');
          window.location.href = "https://apps.apple.com/ae/app/shab-food-ordering-app/id1469194074";
        } else if (/iPod/i.test(window.navigator.userAgent)) {
          //	alert('iPod');
          window.location.href = "http://www.shab.ae";
        } else if (/BlackBerry/i.test(window.navigator.userAgent)) {
          //	alert('BlackBerry');
          window.location.href = "http://www.shab.ae";
        } else if (/IEMobile/i.test(window.navigator.userAgent)) {
          //	alert('IEMobile');
          window.location.href = "http://www.shab.ae";
        } else if (/Opera Mini/i.test(window.navigator.userAgent)) {
          //	alert('Opera Mini');
          window.location.href = "http://www.shab.ae";
        } else {
          //		alert('Desktop');
        }
      }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Routing
import { HomePageRoutingModule } from './home-page-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HomePageRoutingModule
  ]
})
export class HomePageModule { }

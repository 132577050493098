<div class="product-listing-wrapper" style="background-image: url('./assets/images/black-bg.jpg');">
    <div class="container">
        <div *ngIf="!isLoading" class="header-action">
            <div class="back-btn">
                <h4>{{restaurantName}}</h4>
            </div>
            <div class="order-btn">
                <!-- Deep Linking URL Here -->
                <a (click)="deepLinkingURLForwading()">
                    Download the App
                    <img src="assets/images/shab-logo.svg" alt="Shab Logo">
                </a>
            </div>
        </div>
        <div *ngIf="!isLoading" class="row">
            <div class="col-6 col-md-6 col-lg-4" *ngFor="let cat of categoryList">
                <a class="product-items" [routerLink]="['/products', cat._id, zone, cat.name == '.' ? 'un' : cat.name]" [class.disabled]="cat.childrenCounts < 1 ? true : null">
                    <div class="img-box">
                        <img src="{{cat.photo || restLogo}}" alt="Affogato">
                    </div>
                    <h3>{{cat.name == '.' ? '' : cat.name }}</h3>
                </a>
            </div>
        </div>
    </div>
</div>